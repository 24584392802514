.rkt__brand {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.rkt__brand-wrapper {
  display: flex;
  flex-direction: column;
}

.rkt__brand-header p {
  color: var(--color-text);
  font-family: var(--font-familyB);
  font-size: 30px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  margin-top: 1rem;
}

.rkt__brand div {
  flex: 1;
  max-width: 120px;
  min-width: 120px;
  margin: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.rkt__brand-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.rkt__brand img {
  filter: grayscale(100%);
  max-width: 64px;
  margin-bottom: 1rem;
}

.rkt__brand img:hover {
  filter: sepia(100%) hue-rotate(170deg) drop-shadow(0px 5px 10px gold);
}

.tooltip {
  position: relative; /* making the .tooltip span a container for the tooltip text */
  border-bottom: 2px dashed #000; /* little indicater to indicate it's hoverable */
  font-family: var(--font-familyG);
  font-size: 13px;
  line-height: 15px;
}

.tooltip:before {
  content: attr(data-text); /* here's the magic */
  position: absolute;

  /* vertically center */
  bottom: -20%;
  transform: translateY(-10%);
  transform: translateX(-15%);

  /* move to right */
  /*left: 100%;*/
  margin-left: 0px; /* and add a small left margin */

  /* basic styles */
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;

  display: none; /* hide by default */

  opacity: 0;
  transition: 1s opacity;
  transition-delay: 100ms;
}

.tooltip:hover:before {
  display: block;
  opacity: 1;
}

.tooltip.left:before {
  /* reset defaults */
  left: initial;
  margin: initial;

  /* set new values */
  right: 100%;
  margin-right: 15px;
}

.tooltip:after {
  opacity: 0;
  transition: 1s;
  transition-delay: 100ms;
}
.tooltip:hover:after {
  opacity: 1;
}

@media screen and (max-width: 750px) {
  .rkt__brand-items {
    flex-direction: column;
  }

  .rkt__brand-header p {
    font-size: 20px;
  }
}
