.rkt__services {
  display: flex;
  flex-direction: column;

  margin-left: 4rem;
  margin-right: 4rem;
  padding: 2rem;
  background: var(--color-footer);

  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.rkt__services-feature {
  display: flex;
}

/* Customizing feature component as per needs */
.rkt__services-feature .rkt__services-container__feature {
  margin: 0;
}

/* Customizing feature component as per needs */
.rkt__services-feature .rkt__services-container_feature-text {
  max-width: 700px;
}

.rkt__services-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 4rem 0 2rem;
}

.rkt__services-heading h1 {
  font-size: 60px;
  line-height: 60px;
  font-weight: 800;
  font-family: var(--font-familyB);
  text-transform: uppercase;
  max-width: 510px;
}

.rkt__services-heading p {
  font-family: var(--font-familyB);
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: var(--color-subtext);
  cursor: var(--cursor-pointer);
}

.rkt__services-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  margin-top: 1rem;
}

/* Customizing feature component as per needs */
.rkt__services-container .rkt__services-container__feature {
  flex: 1;
  margin: 1rem;
  min-width: 210px;
  display: unset;
  flex-direction: column;
}

/* Customizing feature component as per needs */
.rkt__services-container .rkt__services-container_feature-text {
  margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
  .rkt__services-heading {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0rem;
  }

  .rkt__services-heading p {
    margin-top: 1rem;
    font-size: 18px;
    line-height: 20px;
  }
}

@media screen and (max-width: 650px) {
  /* Customizing feature component as per needs */
  .rkt__services-feature .rkt__services-container__feature {
    flex-direction: column;
  }

  /* Customizing feature component as per needs */
  .rkt__services-feature .rkt__services-container_feature-text {
    margin-top: 0.5rem;
  }

  .rkt__services-heading h1 {
    font-size: 40px;
    line-height: 32px;
  }

  .rkt__services {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .rkt__services-container .rkt__services-container__feature {
    flex: 1;
    margin: 0rem;
    margin-bottom: 1rem;
    min-width: 100px;
    display: unset;
    flex-direction: column;
  }

  .rkt__services {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 0rem;
    margin-right: 0rem;
    padding: 0rem;
  }
}

@media screen and (max-width: 350px) {
  /* Customizing feature component as per needs */
  .rkt__services-container .rkt__services-container__feature {
    margin: 1rem 0;
    min-width: 100%;
  }
}
