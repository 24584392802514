@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@300;400;500;700;800;900&family=Press+Start+2P&family=Smooch+Sans:wght@100;200;300;400;500;600;700;800;900&family=Yanone+Kaffeesatz:wght@200;300;400;500;600;700&display=swap");

:root {
  --font-family: "Alegreya Sans SC", sans-serif;
  --font-familyB: "Smooch Sans", sans-serif;
  --font-familyG: "Press Start 2P", cursive;
  --font-family2: "Yanone Kaffeesatz", sans-serif;

  --gradient-text: linear-gradient(89.97deg, #ffd105 1.84%, #ff803c 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #00c3ff -13.86%, #fd00d3 99.55%);

  --color-bg: #040c18;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;

  --cursor-rocket: url("./assets/Rocket_cursor.png"), pointer !important;
  --cursor-pointer: url("./assets/Bow_Arrow.png"), pointer !important;
}
