.rkt__header {
  display: flex;
}

.rkt__header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin-right: 5rem;
  margin-left: 5rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.rkt__header-content h1 {
  font-family: var(--font-familyB);
  text-transform: uppercase;
  font-weight: 800;
  font-size: 69px;
  line-height: 75px;
  letter-spacing: -0.04rem;
}

.rkt__header-content p {
  font-family: var(--font-familyB);
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 32px;
  color: var(--color-text);

  margin-top: 1.5rem;
}

.rkt__header-content_input {
  width: 100%;
  margin: 2rem 0 1rem;

  display: flex;
}

.rkt__header-content_input-text {
  flex: 2;
  width: 100%;
  min-height: 50px;
  background: #052d56;
  font-family: var(--font-family2);
  font-size: 20px;
  text-transform: uppercase;
  line-height: 27px;
  background: var(--color-footer);
  border: 2px solid var(--color-footer);
  padding: 0 1 rem;
  outline: none;
  color: #fff;
  align-items: center;
  justify-content: center;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.rkt__header-content_input input {
  flex: 2;
  width: 100%;
  min-height: 50px;
  background: #052d56;
  font-family: var(--font-family2);
  font-size: 20px;
  text-transform: lowercase;
  line-height: 27px;
  background: var(--color-footer);
  border: 2px solid var(--color-footer);
  padding: 0 1 rem;
  outline: none;
  color: #fff;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.rkt__button {
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-familyG);
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 27px;
  background: #ff4820;
  color: #fff;
  cursor: var(--pointer-cursor);
  outline: none;
  padding: 0 1rem;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.rkt__button button,
.button,
button {
  cursor: var(--pointer-cursor);
}

.rkt__header-content_input button {
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-familyG);
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 27px;
  background: #ff4820;
  color: #fff;
  cursor: var(--pointer-cursor);
  outline: none;
  padding: 0 1rem;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.rkt__header-content_people {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-top: 0rem;
}

.rkt__header-content_people img {
  width: 181.79px;
  height: 38px;
}

.rkt__header-content_people p {
  margin-left: 1rem;
  margin-bottom: 1rem;
  font-family: var(--font-familyB);
  font-weight: 500;
  font-size: 20px;
  line-height: 15px;
  color: #fff;
  text-align: center;
}

.rkt__header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rkt__header-image img {
  width: 100%;
  height: 100%;
}

.rkt-1-ani-image {
  max-width: 512px;
  max-height: 512px;
}

@media screen and (max-width: 1050px) {
  .rkt__header {
    flex-direction: column;
  }

  .rkt__header-content {
    margin: 3rem;
  }
}

@media screen and (max-width: 650px) {
  .rkt__header h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .rkt__header p {
    font-size: 20px;
    line-height: 24px;
  }

  .rkt__header-content_people {
    flex-direction: column;
  }

  .rkt__header-content_people p {
    margin: 0;
  }

  .rkt__header-content_input input,
  .rkt__header-content_input button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .rkt__header h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .rkt__header p {
    font-size: 20px;
    line-height: 24px;
  }

  .rkt__header-content_input input,
  .rkt__header-content_input button {
    font-size: 12px;
    line-height: 16px;
  }
}
