.rkt__footer {
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--color-footer);
}

.rkt__footer-heading {
  width: 100%;
  text-align: center;

  margin-bottom: 3rem;
}

.rkt__footer-heading h1 {
  font-family: var(--font-familyB);
  font-weight: 800;
  font-size: 55px;
  line-height: 75px;

  text-align: center;
  letter-spacing: -0.02em;
}

.rkt__footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;
  border: 2px solid #fff;
  text-align: center;

  margin-bottom: 10rem;
  cursor: var(--pointer-cursor);
}

.rkt__footer-btn p {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  font-family: var(--font-familyB);
  font-size: 25px;
  text-transform: uppercase;
  line-height: 21px;
  color: #ffffff;
  word-spacing: 5px;
  padding: 5px;
  cursor: var(--cursor-pointer);
}

.rkt__footer-btn p:hover {
  color: greenyellow;
  border: 1px solid yellow;
}

.rkt__footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;

  width: 100%;
  text-align: left;
}

.rkt__footer-links div {
  font-family: var(--font-family2);
  width: 250px;
  margin: 1rem;
}

.rkt__footer-links div h4 {
  font-size: 26px;
  text-transform: uppercase;
}

.rkt__footer-links_logo {
  display: flex;
  flex-direction: column;
}

.rkt__footer-links_logo img {
  width: 350px;
  height: 80px;

  margin-bottom: 1rem;
}

.rkt__footer-links_div {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: left;
  text-align: left;
  flex-direction: column;
}

.rkt__footer-links_div h4 {
  font-family: var(--font-family2);
  font-size: 18px;
  line-height: 40px;
  color: #ffffff;
}

.rkt__footer-links_div p {
  font-family: var(--font-family2);
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;

  margin: 0.5rem 0;
  cursor: var(--cursor-pointer);
}

.rkt__footer-copyright {
  margin-top: 2rem;
  text-align: center;
  width: 100%;
}

.rkt__footer-copyright p {
  font-family: var(--font-familyB);
  font-size: 16px;
  line-height: 14px;
  color: #ffffff;
}

@media screen and (max-width: 850px) {
  .rkt__footer-heading h1 {
    font-size: 40px;
    line-height: 50px;
  }
}

@media screen and (max-width: 550px) {
  .rkt__footer-heading h1 {
    font-size: 34px;
    line-height: 42px;
  }

  .rkt__footer-links div {
    margin: 0.5rem 0;
    align-items: center;
  }

  .rkt__footer-links p {
    line-height: 10px;
  }

  .rkt__footer-btn {
    margin-bottom: 5rem;
  }

  .rkt__footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }

  .rkt__footer-links_logo img {
    width: 250px;
    height: 60px;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 550px) {
  .rkt__footer-heading h1 {
    font-size: 27px;
    line-height: 38px;
  }
}
