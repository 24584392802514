.rkt__possibility {
  display: flex;
  flex-direction: row;
}

.rkt__possibility-image {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-right: 2rem;
}

.rkt__possibility-image img {
  width: 100%;
  height: 100%;
}

.rkt__possibility-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.rkt__possibility-content h4 {
  font-family: var(--font-family2);
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #71e5ff;
}
.rkt__possibility-content h4:last-child {
  color: #ff8a71;
}
.rkt__possibility-content h1 {
  font-family: var(--font-familyB);
  font-weight: 800;
  font-size: 45px;
  line-height: 45px;

  margin: 1rem 0;
}
.rkt__possibility-content p {
  font-family: var(--font-familyB);
  font-size: 30px;
  line-height: 30px;
  color: #81afdd;

  margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
  .rkt__possibility {
    flex-direction: column;
  }

  .rkt__possibility-image {
    margin: 1rem 0;
  }

  .rkt__possibility-content {
    margin-top: 2rem;
  }
}
