.rkt__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: var(--cursor-rocket);

  padding: 2rem 6rem;
}

.rkt__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: var(--cursor-pointer);
}

.rkt__navbar-links_logo {
  margin-right: 2rem;
}

.rkt__navbar-links_logo img {
  width: 203.75px;
  height: 50px;
}

.rkt__navbar-links_container {
  display: flex;
  flex-direction: row;
}

.rkt__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rkt__navbar-links_container p,
.rkt__navbar-sign p,
.rkt__navbar-menu_container p {
  color: #fff;
  font-family: var(--font-familyB);
  font-weight: 500;
  font-size: 27px;
  line-height: 30px;
  text-transform: uppercase;

  margin: 0 1rem;
}

.multibutton {
  background-color: none;
  display: inline-block;
  padding: 8px;
  color: #fff;
  border: 1px solid #fff;
  text-align: center;
  outline: none;
  text-decoration: none;
}

.multibutton-button-trigger {
  color: yellow;
}

.multibutton:hover {
  background-color: whitesmoke;
  color: #000;
  cursor: var(--cursor-pointer);
}

.multibutton:active {
  background-color: purple;
  color: #000;
  cursor: var(--cursor-pointer);
}

.rkt__navbar-menu {
  margin-left: 1rem;

  display: none;
  position: relative;
}

.rkt__navbar-menu svg {
  cursor: pointer;
}

.rkt__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  text-align: end;
  background: var(--color-footer);
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.rkt__navbar-menu-container p {
  margin: 1rem 0;
}

.rkt__navbar-menu_container-links-sign {
  display: none;
}

@media screen and (max-width: 1250px) {
  .rkt__navbar-links_container p {
    font-size: 18px;
  }
}

@media screen and (max-width: 1050px) {
  .rkt__navbar-links_container {
    display: none;
  }

  .rkt__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .rkt__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 600px) {
  .rkt__navbar {
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 550px) {
  .rkt__navbar {
    padding: 2rem;
  }

  .rkt__navbar-sign {
    display: none;
  }

  .rkt__navbar-menu_container {
    top: 20px;
  }

  .rkt__navbar-menu-container-links-sign {
    display: block;
  }
}
